import fetchFromApiServer from 'services/api';

export function fetchFundDetails(fund_ID) {
  const url = `funds/fund-details/${fund_ID}`;
  return fetchFromApiServer('GET', url, fund_ID);
}

export function fetchPdfFund(data) {
  const url = `funds/pdf`;
  return fetchFromApiServer('POST', url, data);
}

export function fetchCompareFundData(data) {
  const url = `funds/compare`;
  return fetchFromApiServer('POST', url, data);
}

export function fetchUpdateCompareFundData(data) {
  const url = `funds/fund-compare-update`;
  return fetchFromApiServer('PUT', url, data);
}

export function fetchCompareFundField(data) {
  const url = `funds/compare-fields`;
  return fetchFromApiServer('POST', url, data);
}

export function fetchUsersList() {
  const url = `funds/getListUsers`;
  return fetchFromApiServer('GET', url, {});
}

export function fetchCompaniesList() {
  const url = `funds/getListCompanies`;
  return fetchFromApiServer('GET', url, {});
}

export function fetchPeoplesList() {
  const url = `funds/getListPeoples`;
  return fetchFromApiServer('GET', url, {});
}

export function addPerson(data) {
  const url = `funds/addPerson`;
  return fetchFromApiServer('POST', url, data);
}

export function fetchCioUsersList() {
  const url = `funds/cio-users-list`;
  return fetchFromApiServer('GET', url, {});
}

export function fetchSendEmail(data) {
  const url = `funds/send-mail`;
  return fetchFromApiServer('POST', url, data);
}

export function fetchSendEmailTemplate(data) {
  const url = `funds/send-mail-template`;
  return fetchFromApiServer('POST', url, data);
}

export function fetchDDDetails(fund_ID) {
  const url = `funds/get-ddChecklist`;
  return fetchFromApiServer('GET', url, {}, fund_ID);
}

export function fetchFundBenchmark(fund_ID) {
  const url = `funds/fund-quartiles`;
  return fetchFromApiServer('GET', url, {}, fund_ID);
}

export function updateFundBenchmark(data) {
  const url = `funds/set-benchmarks`;
  return fetchFromApiServer('PUT', url, data);
}

export function deleteFundBenchmark(data) {
  const url = `funds/delete-benchmarks`;
  return fetchFromApiServer('DELETE', url, {}, data);
}

export function addFundBenchmark(data) {
  const url = `funds/add-benchmarks`;
  return fetchFromApiServer('POST', url, {}, data);
}

export function fetchScoringMatrix(data) {
  const url = `funds/get-scoringMatrix`;
  return fetchFromApiServer('GET', url, {}, data);
}

export function updateDDList(data) {
  const url = `funds/update-ddChecklist`;
  return fetchFromApiServer('PUT', url, data);
}

export function updateScoringMatrix(data) {
  const url = `funds/update-scoringMatrix`;
  return fetchFromApiServer('PUT', url, data);
}

export function fetchSectionList(data) {
  const url = `funds/fund-sections-review`;
  return fetchFromApiServer('POST', url, data);
}

export function fetchFundSectionReview(fund_ID) {
  const url = `funds/fund-sections-review/${fund_ID}`;
  return fetchFromApiServer('GET', url, fund_ID);
}

export function fetchSourceDocList(doc_path) {
  const url = `funds/fund-document-name`;
  return fetchFromApiServer('POST', url, doc_path);
}

export function fetchFundDocuments(fund_ID) {
  const url = `funds/fund-documents/${fund_ID}`;
  return fetchFromApiServer('GET', url, fund_ID);
}

export function fetchFundDocumentsType(data) {
  const url = `funds/fund-document-type`;
  return fetchFromApiServer('PUT', url, data);
}

export function updateFundStatus(requestObject) {
  const url = `funds/update-fund-status`;
  return fetchFromApiServer('POST', url, requestObject);
}

export function fetchFundPdfUrl(data) {
  const url = `funds/source-docs/signurl`;
  return fetchFromApiServer('POST', url, data);
}

export function fetchDisplayConfig(data) {
  const url = `funds/get-user-configs`;
  return fetchFromApiServer('GET', url, data);
}

export function updateBenchmarkQuartiles(data) {
  const url = `funds/update-benchmark-quartile`;
  return fetchFromApiServer('POST', url, data);
}

export function addNotes(data) {
  const url = `funds/add-notes`;
  return fetchFromApiServer('POST', url, data);
}

export function updateNotes(data) {
  const url = `funds/update-notes`;
  return fetchFromApiServer('POST', url, data);
}

export function deleteNotes(data) {
  const url = `funds/delete-notes`;
  return fetchFromApiServer('POST', url, data);
}

export function getNotes(fund_id) {
  const url = `funds/get-notes/${fund_id}`;
  return fetchFromApiServer('GET', url, fund_id);
}

export function fetchPortfolioLinkDetails(requestObject) {
  const url = `funds/person-details`;
  return fetchFromApiServer('POST', url, requestObject);
}
export function fetchCompanyDetails(requestObject) {
  const url = `funds/company-details`;
  return fetchFromApiServer('POST', url, requestObject);
}

// Add a new reference
export function addReference(requestObject) {
  const url = `funds/add-reference`;
  return fetchFromApiServer('POST', url, requestObject);
}

// Edit an existing reference
export function editReference(refId, requestObject) {
  const url = `funds/edit-reference/${refId}`;
  return fetchFromApiServer('PUT', url, requestObject);
}

// Delete a reference
export function deleteReference(requestObject) {
  const url = `funds/delete-reference`;
  return fetchFromApiServer('DELETE', url, requestObject);
}

// Get all references for a specific fund
export function getReferences(fund_id) {
  const url = `funds/get-references/${fund_id}`;
  return fetchFromApiServer('GET', url, fund_id);
}
