import { currencySymbols, TOKEN_KEY } from 'services/constants';
import moment from 'moment';

const validateEmail = (email) => {
  const pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  return pattern.test(email);
};

const formatNumber = (num, currency = 'INR') => {
  if (num) {
    num = parseFloat(num);
    if (parseInt((num + '').split('.')[1])) {
      return (
        <>
          <span style={{ fontFamily: 'Verdana' }}>
            {currencySymbols[currency]}
          </span>
          {parseFloat(num.toFixed(2)).toLocaleString?.()}
        </>
      );
    }
  }
  return (
    <>
      <span style={{ fontFamily: 'Verdana' }}>{currencySymbols[currency]}</span>{' '}
      {(num || 0).toLocaleString?.()}
    </>
  );
};

const setToken = (value) => {
  sessionStorage.setItem(TOKEN_KEY, value);
};

const getToken = () => {
  return sessionStorage.getItem(TOKEN_KEY);
};

const dateFormat = (input, format) => {
  if (input && format) {
    return moment(input).format(format);
  } else {
    return '';
  }
};

const getJsonValue = (dataArray, key) => {
  let data = null;

  // If data is an array and contains the key
  if (Array.isArray(dataArray)) {
    data = dataArray.find((item) => item?.hasOwnProperty(key))?.[key];
  } else if (
    dataArray &&
    typeof dataArray === 'object' &&
    dataArray.hasOwnProperty(key)
  ) {
    // If dataArray is an object and contains the key
    data = dataArray[key];
  }

  // Special handling for the 'values' key
  if (key === 'values') {
    if (Array.isArray(data)) {
      // If 'values' is already an array, return it as is
      return data;
    } else if (data && typeof data === 'object') {
      // If 'values' is an object, convert it to an array
      return [data];
    } else {
      // If 'values' is not found or is not an array/object, return an empty array
      return [];
    }
  }

  return data;
};

// function getJsonValue(dataArray, key) {
//   const data = dataArray?.find((item) => item?.hasOwnProperty(key));
//   return data ? data[key] : undefined;
// }
const checkUrlExpiry = (url) => {
  const parsedUrl = new URL(url);
  const expires = parsedUrl.searchParams.get('Expires'); // Extract the Expires parameter
  if (!expires) {
    console.error('Expires parameter not found in the URL.');
    return false;
  }

  const expiryTime = Number(expires); // Convert to a number
  const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

  if (currentTime < expiryTime) {
    console.log('URL is still valid.');
    return true; // URL is valid
  } else {
    console.log('URL has expired.');
    return false; // URL has expired
  }
};
const downloadFile = (url, document_name) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = document_name; // Use the same file name
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
export {
  validateEmail,
  formatNumber,
  setToken,
  getToken,
  dateFormat,
  getJsonValue,
  checkUrlExpiry,
  downloadFile,
};
